import * as React from 'react'

import * as LabelPrimitive from '@radix-ui/react-label'
import { cva, type VariantProps } from 'class-variance-authority'

import { cn } from '@design-system/lib/utils'

export const labelVariantClassName = cva(
  'text-subtle peer-disabled:cursor-not-allowed peer-disabled:opacity-70 peer-focus:text-primary',
  {
    variants: {
      size: {
        default: 'font-label',
        small: 'font-label-s',
      },
    },
    defaultVariants: {
      size: 'default',
    },
  },
)
export interface LabelProps {
  hint?: React.ComponentType
  size?: VariantProps<typeof labelVariantClassName>['size']
}

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & LabelProps
>(({ className, hint: Hint, size, ...props }, ref) => {
  if (!Hint)
    return (
      <LabelPrimitive.Root
        className={cn(labelVariantClassName({ size }), className)}
        ref={ref}
        {...props}
      />
    )
  return (
    <div
      className={cn(
        'flex items-center gap-x-xs hover:cursor-pointer peer-focus:[&>label]:text-primary',
        className,
      )}
    >
      <LabelPrimitive.Root
        className={cn(labelVariantClassName({ size }))}
        ref={ref}
        {...props}
      />
      <Hint />
    </div>
  )
})
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
