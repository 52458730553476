import { forwardRef } from 'react'

import type { VariantProps } from 'class-variance-authority'
import { cva } from 'class-variance-authority'

import { cn } from '@design-system/lib/utils'

import { Tooltip, TooltipContent, TooltipTrigger } from '../tooltip/tooltip'

export const buttonIconVariants = cva(
  'inline-flex hover:bg-neutral-hover items-center justify-center rounded border text-subtle disabled:pointer-events-none disabled:opacity-50 focus-visible:relative focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline peer-focus:outline peer-focus:outline-2 peer-focus:outline-offset-2 disabled:cursor-not-allowed',
  {
    variants: {
      state: {
        default: '',
        selected: 'bg-neutral',
      },
      variant: {
        outline: 'border text-primary hover:text-primary-hover',
        rounded: 'rounded-full active:drop-shadow-md',
        neutral: 'bg-neutral text-subtle hover:bg-neutral-hover',
        icon: 'active:drop-shadow-md border-none',
        'rating-positive':
          'peer-checked:bg-secondary peer-checked:text-inverse hover:text-inverse hover:bg-secondary-hover text-success',
        'rating-negative':
          'peer-checked:bg-error peer-checked:text-inverse hover:text-inverse hover:bg-error-hover text-error',
        toggle:
          'border rounded-none first:rounded-l-lg last:rounded-r-lg first:border-r last:border-l-0',
      },
      size: {
        small: 'p-xs size-6 min-w-6 min-h-6',
        medium: 'p-s size-8 min-w-8 min-h-8',
        large: 'p-m size-10 min-w-10 min-h-10',
      },
    },
    defaultVariants: {
      variant: 'icon',
      state: 'default',
      size: 'medium',
    },
  },
)

export interface ButtonIconProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonIconVariants> {
  icon: React.ComponentType<{ size?: string | number }>
  /**
   * String description of what the button does. Important for screen readers.
   */
  label: string
}

const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ className, label, icon: Icon, variant, size, state, ...props }, ref) => {
    return (
      <Tooltip delayDuration={600}>
        <TooltipTrigger asChild>
          <button
            className={cn(
              buttonIconVariants({ variant, size, state }),
              className,
            )}
            ref={ref}
            {...props}
          >
            <Icon size={size === 'small' ? 12 : 16} />
            <span className="sr-only">{label}</span>
          </button>
        </TooltipTrigger>
        <TooltipContent>{label}</TooltipContent>
      </Tooltip>
    )
  },
)
ButtonIcon.displayName = 'ButtonIcon'

export { ButtonIcon }
